import { defineStore } from "pinia";

export const useNotifications = defineStore("notifications", () => {
  const unreadPage = ref(1);
  const allPage = ref(1);

  const unread = ref([]);
  const all = ref([]);

  const {
    data: unreadData,
    refresh: refreshUnread,
    status: unreadStatus,
  } = useAPI("/notifications/", {
    query: { read: false, page: unreadPage },
    watch: [unreadPage],
    onResponse({ request, response, options }) {
      if (unreadPage.value === 1) {
        unread.value = response._data.results;
      } else {
        unread.value.push(...response._data.results);
      }
    },
  });

  const {
    data: allData,
    refresh: refreshAll,
    status: allStatus,
  } = useAPI("/notifications/", {
    query: { page: allPage },
    watch: [allPage],
    onResponse({ request, response, options }) {
      if (allPage.value === 1) {
        all.value = response._data.results;
      } else {
        all.value.push(...response._data.results);
      }
    },
  });

  const markAllAsRead = async () => {
    const data = await Promise.all(
      unread.value.map((notification) =>
        useAPI(`/notifications/${notification.id}/`, {
          method: "PATCH",
          body: {
            read: true,
          },
        }),
      ),
    );

    if (data.some((response) => response.status.value === "success")) {
      unread.value = [];
      unreadData.value.count = 0;
      all.value.forEach((notification) => (notification.read = true));
    }
  };

  const markAsRead = async (id) => {
    const { status } = await useAPI(`/notifications/${id}/`, {
      method: "PATCH",
      body: {
        read: true,
      },
    });
    if (status.value === "success") {
      // mark notification as read in store
      const notifyIndex = useFindIndex(unread.value, { id });
      if (notifyIndex >= 0) {
        unread.value.splice(notifyIndex, 1); // remove from unread list
        unreadData.value.count--;
      }
      // mark in all list
      const notifyIndexAll = useFindIndex(all.value, { id });
      if (notifyIndexAll >= 0) {
        all.value[notifyIndexAll].read = true;
      }
    }
  };

  return {
    unread,
    unreadData, // count & next
    unreadPage,
    unreadStatus,
    all,
    allData, // count & next
    allPage,
    allStatus,
    refreshUnread,
    refreshAll,
    markAsRead,
    markAllAsRead,
  };
});
