import { differenceInDays, formatDistance, formatRelative } from "date-fns";

/**
 * humanDate – format Date to str repr.
 *
 * Into the past:
 *
 * - Today at 8:21 PM
 * - Yesterday at 8:21 PM
 * - Last Sunday at 8:21 PM
 * - Last Saturday at 8:21 PM
 * - Last Friday at 8:21 PM
 * - Last Thursday at 8:21 PM
 * - Last Wednesday at 8:21 PM
 * - 7 days ago
 * - ...
 * - 29 days ago
 * - About 1 month ago
 * - About 2 months ago
 * - ...
 * - About 1 year ago
 *
 * Into the future:
 *
 * - Today at 8:21 PM
 * - Tomorrow at 8:21 PM
 * - Next Thursday at 8:21 PM
 * - Next Friday at 8:21 PM
 * - Next Saturday at 8:21 PM
 * - Next Sunday at 8:21 PM
 * - Next Monday at 8:21 PM
 * - In 7 days
 * - ...
 * - In 29 days
 * - In about 1 month
 * - In about 2 month
 * - ...
 * - In about 1 year
 */
export default (date) => {
  const today = new Date();
  const distance = Math.abs(differenceInDays(date, today));
  let dateStr = "";
  if (distance > 6) {
    dateStr = useUpperFirst(formatDistance(date, today, { addSuffix: true }));
  } else {
    dateStr = useUpperFirst(formatRelative(date, today, { addSuffix: true }));
    if (differenceInDays(date, today) > 0 && distance > 1 && distance < 7) {
      return `Next ${dateStr}`;
    }
  }
  return dateStr;
};
